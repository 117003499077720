import React from 'react'
import Home from './components/Home'
import {BrowserRouter as Router , Routes , Route } from 'react-router-dom'
import './App.css'

import Products from './components/Products'
// import Navbar from './components/Navbar'
import About from './components/About'


const App = () => {
  return (
    
    <Router>
    <Routes> 
      <Route exact path='/' element={<Home/>} />
      <Route exact path='/products' element={<Products/>} />
      <Route exact path='/about' element={<About/>} />
  
    </Routes>

{/* <Navbar/> */}
  </Router>
  )
}

export default App