import React from 'react'
import Navbar from './Navbar'
import './Products.css'
const Products = () => {



  return (
<div   className="fontss">
  <body>
    
    <div style={{padding: '0',margin:'0'}} >
  
</div>
<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Montserrat:400,700"/>

<section>
<Navbar/>
  <div id="parallax" class="parallax-item text1" style={{marginTop: '-112px'}}>
    <h2 style={{marginTop:'237px'}}  data-aos="zoom-in"
     data-aos-duration="1000" 
     >Antidote</h2>
   
  </div>
  
  <div class="parallax-item">
    <div className='roww' style={{    display: 'flex', marginTop: '50px',justifyContent: 'center',}}>
      <div >  <img src={require('../img/flex1.jpg')} alt=""  style={{width: '517px', backgroundAttachment: 'fixed',position:"absolute"}} className='flex1' />  <h1 className='text2'  style={{position:'relative'}}>Recipes</h1> </div>
      <div>   <img src={require('../img/flex2.jpg')}  style={{width: '517px', 
   }}  className='flex2'/></div>
  
    </div>
    <div className='roww2' style={{    display: 'flex',gap: '2rem', marginTop: '50px',justifyContent: 'center',margin: '30px'}}>
      <div >  <img src={require('../img/flex3.jpg')} alt=""  style={{width: '517px',}} className='flex3' /> </div>
      <div>   <img src={require('../img/flex4.jpg')}  style={{width: '517px'}}  className='flex4'/></div>
  
    </div>
  </div>
  <div class="parallax-item">
    <h2>Div 3</h2>
  </div>
  <div class="parallax-item">
    <h2>Div 4</h2>
  </div>
</section>
  </body>
</div>
  )
}

export default Products